<template>
  <div>
    <div class="flex justify-between mb-6">
      <h5 class="text-12 text-blue-900">Pay with</h5>
      <div>
        <paystack
          type="button"
          class="flex items-center text-blue-500 font-semibold text-sm"
          :amount="100"
          :callback="addCard"
          :disabled="form.loading"
        >
          {{ form.loading ? "Verifying" : "+ Add New" }}
        </paystack>
      </div>
    </div>

    <!-- CREDPAL CARDS -->
    <template v-if="cards.loading || form.loading">
      <sm-loader class="mb-10" />
    </template>
    <template v-for="(card, n) in credpalCards">
      <div
        :key="n"
        class="flex justify-between mb-3 cursor-pointer"
        @click="selectMethod(card)"
        v-if="hasCredPalCard"
      >
        <h5
          class="text-12 font-normal text-gray-800 inline-flex"
          :class="method === card ? 'opacity-100' : 'opacity-50'"
        >
          <svg
            width="35"
            height="24"
            viewBox="0 0 35 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="35" height="23.3333" rx="3" fill="#242D4F" />
            <circle opacity="0.8" cx="14.5" cy="11.5" r="5.5" fill="white" />
            <circle opacity="0.5" cx="20.5" cy="11.5" r="5.5" fill="white" />
          </svg>
          <span class="mt-1 ml-2" v-if="card.type === 'debit'"
            >Credpal Debit Card</span
          >
          <span class="mt-1 ml-2" v-if="card.type === 'credit'"
            >Credpal Credit Card</span
          >
        </h5>
        <span
          class="rounded-full px-2 py-2"
          :class="method === card ? 'bg-blue-500' : 'border border-credpal-300'"
        >
          <svg
            width="10"
            height="9"
            viewBox="0 0 12 9"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 1L4.125 8L1 4.81818"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </span>
      </div>
      <div
        class="w-full mb-4 mt-2 bg-gray-800"
        style="height: 1px; opacity: 0.04;"
        :key="card.id"
      ></div>
    </template>
    <!-- CREDPAL CARDS -->
    <!-- REPAYMENT CARDS -->
    <div class="">
      <div class="text-right mb-3">
        <a href="#!" class="text-gray-700 text-sm" @click.prevent="toggleCards">
          {{ showCards ? "Hide" : "Show" }}
        </a>
      </div>
      <template v-if="showCards">
        <template v-if="cards.loading || form.loading">
          <sm-loader class="mb-10" />
        </template>
        <template v-else-if="cards.length">
          <div class="grid grid-cols-1 gap-4 mb-3" style="letter-spacing: .1em">
            <template v-for="(card, n) in cards">
              <div
                :key="n"
                class="flex justify-between mb-4 cursor-pointer"
                @click.prevent="selectMethod(card)"
              >
                <h5
                  class="text-10 md:text-12 font-normal text-gray-800 inline-flex"
                  :class="method === card ? 'opacity-100' : 'opacity-50'"
                >
                  <img
                    :src="getProvider(card.data.card_type).logo"
                    alt="Visa"
                    style="height: 10px"
                  />
                  <span
                    class="ml-5 text-gray-700"
                    v-html="`•••• &nbsp;${card.data.last4}`"
                  ></span>
                  <span class="text-gray-700 ml-16"
                    >{{ card.data.exp_month }}/{{
                      card.data.exp_year.match(/\d{2}$/)[0]
                    }}
                  </span>
                </h5>
                <span
                  class="rounded-full px-2 py-2"
                  :class="
                    method === card
                      ? `bg-${getProvider(card.data.card_type).color}-500`
                      : 'border border-credpal-300'
                  "
                >
                  <svg
                    width="10"
                    height="9"
                    viewBox="0 0 12 9"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11 1L4.125 8L1 4.81818"
                      stroke="white"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
            </template>
          </div>
        </template>
        <template v-else>
          <div class="text-sm text-gray-700 mb-10">
            You have no repayment cards at the moment. Click the button below to
            add a new one.
          </div>
        </template>
        <!-- REPAYMENT CARDS -->
        <!-- ADD REPAYMENT CARDS -->
      </template>
    </div>
    <modal
      className="w-11/12 md:w-2/5 xl:w-3/10 text-center pt-24"
      ref="successModal"
    >
      <img
        :src="assets.icons.lg.checkmark"
        class="mx-auto mb-8"
        height="90"
        alt="Check Mark"
      />
      <div class="text-sm mb-10">
        Card Saved successfully!
      </div>
    </modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      method: null,
      showCards: true,
      credpalCard: {
        method: "credpal_card"
      },
      providers: {
        mastercard: {
          logo: require("@/assets/mastercard.svg"),
          color: "red"
        },
        visa: {
          logo: require("@/assets/visa-blue.svg"),
          color: "blue"
        },
        default: {
          logo: require("@/assets/credit-card.svg"),
          color: "gray"
        }
      },
      form: this.$options.basicForm([])
    };
  },
  computed: {
    credpalCards() {
      return this.resources.personalCards?.data?.map(card => ({
        method: "credpal_card",
        account_id: card.id,
        type: card.type
      }));
    },
    cards() {
      return this.resources.repaymentCards?.data?.map(card => ({
        method: "repayment_card",
        data: card
      }));
    },
    hasCredPalCard() {
      return this.user?.personal_account?.status == "active";
    }
  },
  beforeMount() {
    this.getCards(false);
  },
  methods: {
    toggleCards() {
      this.showCards = !this.showCards;
    },
    selectMethod(method) {
      this.method = method;
      this.$emit("change", method);
    },
    getProvider(cardType) {
      switch (cardType?.toLowerCase()?.trim()) {
        case "mastercard":
        case "mastercard credit":
        case "mastercard debit":
          return this.providers.mastercard;
        case "visa":
        case "visa credit":
        case "visa debit":
          return this.providers.visa;
        default:
          return this.providers.default;
      }
    },
    async getCards(forced = true) {
      this.loadResource("repaymentCards", forced);
    },
    async addCard({ reference }) {
      this.form.loading = true;

      await this.sendRequest("personal.repaymentCards.new", {
        data: {
          user_id: this.user.id,
          reference
        },
        success: () => {
          // this.$refs.successModal.open();
          this.getCards();
        },
        error: error => {
          this.form.error = error;
        }
      });

      this.form.loading = false;
    }
  }
};
</script>
